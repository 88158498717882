import { cache, createAsync, useParams } from "@solidjs/router";
import { createSignal, Match, onMount, Show, Switch } from "solid-js";
import {
  BrandCollectionComponent,
  getInitialLandingPageState,
  getFinalLandingPageState,
  BrandCollectionLandingPageState,
} from "~/components/gifting/brand-collection-landing/gift_brand_collection";
import { clientRepo } from "~/server/apis/client_repo";
import { createJob } from "~/utils/job";
import { Header } from "~/types";
import { GiftBrandListingData } from "~/components/gifting/brand-collection-landing/cards_list";
import LocalStorageUtil from "~/utils/local_storage";
import {
  getMultibrandHomeBrandListFromLocalStorage,
  getV3GiftBoxRouteData,
  getV3GiftingDataStorageKey,
  gift_v3cache_duration_in_minutes,
  V3GiftBoxRouteData,
} from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { LandingPageLoadingState } from "~/components/gifting/brand-collection-landing/brand_collection_components";
import attachPopStateListener from "~/utils/popstate_listener";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import {
  getGiftBoxV3HomeRouteData$Server,
  GiftBoxV3HomeRouteData,
} from "~/server/data/gifting_v3/gift_box_v3_home_route_data";

export default function BrandCollection() {
  const HomeRouteData$C = cache(
    getGiftBoxV3HomeRouteData$Server,
    "GiftBoxV3HomeRouteData"
  );

  const params = useParams();
  const giftingKey = params.giftingKey as string;
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>();

  const routeData$Server = createAsync(
    async () => {
      return HomeRouteData$C();
    },
    { deferStream: true }
  );

  const getGiftBoxDataJob = createJob<V3GiftBoxRouteData>({
    initialJob: async () => {
      return await getV3GiftBoxRouteData(giftingKey);
    },
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
    },
  });

  function getIsSmartGCFromLocalStorage(): boolean | null {
    let isSmartGC = LocalStorageUtil.getItem<string>(
      getV3GiftingDataStorageKey(giftingKey, "isSmartGC")
    ) as boolean | null;

    return isSmartGC;
  }

  function getLandingPageData(): LandingPageData {
    let brands = getMultibrandHomeBrandListFromLocalStorage(giftingKey);
    let coinsCount = getGiftBoxDataJob.jobResult()?.coinsCount;
    let unwrapped = hasClickedUnwrap();
    let isSmartGC = getIsSmartGCFromLocalStorage();
    let isCoinsCountAvailable = coinsCount !== undefined;

    let showTooltip = LocalStorageUtil.getItem(
      getV3GiftingDataStorageKey(giftingKey, "showTooltip")
    ) as boolean | null;

    let state =
      brands && isCoinsCountAvailable && unwrapped
        ? getFinalLandingPageState({
            showTooltip: showTooltip ?? true,
          })
        : getInitialLandingPageState();
    return {
      brands: brands,
      isSmartGC: isSmartGC,
      coinsCount: coinsCount,
      state: state,
      isRtu: getGiftBoxDataJob.jobResult()?.isRtu ?? false,
      phoneNumber:
        getGiftBoxDataJob.jobResult()?.giftBoxMetadta?.customer?.phoneNumber,
    };
  }

  function hasClickedUnwrap(): boolean {
    let val = LocalStorageUtil.getItem<boolean>(
      getV3GiftingDataStorageKey(giftingKey, "clickedUnwrap")
    );
    return val === true;
  }

  function setClickedUnwrap() {
    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(giftingKey, "clickedUnwrap"),
      true,
      gift_v3cache_duration_in_minutes
    );
  }

  const gitingV3defaultBg =
    "https://firebasestorage.googleapis.com/v0/b/flutter-festival-a9d56.appspot.com/o/multi-brand-jewellery-bg.png?alt=media&token=5ceced0e-09cf-4de1-a2f4-5ec70170ce0c";

  async function getBrandsList(
    sessionId: string
  ): Promise<GiftBrandListingData[]> {
    let brands = getMultibrandHomeBrandListFromLocalStorage(giftingKey);
    if (brands) {
      return brands;
    } else {
      let productSearchResponse = await clientRepo.getProductSearch({
        query: "",
        headers: {
          [Header.SessionId]: sessionId,
        },
      });

      let brands: GiftBrandListingData[] =
        productSearchResponse.data[0].products.map((product) => {
          return {
            id: product.id,
            plainLogoUrl: product.voucherProduct.plainLogoUrl,
            cardBackgroundColor: product.voucherProduct.cardBackgroundColor,
            title: product.voucherProduct.title,
            heroImageUrl: product.voucherProduct.heroImageUrl ?? undefined,
            status: product.voucherProduct.status,
          };
        });

      LocalStorageUtil.setItem(
        getV3GiftingDataStorageKey(giftingKey, "brands"),
        JSON.stringify(brands),
        gift_v3cache_duration_in_minutes
      );

      return brands;
    }
  }

  onMount(() => {
    attachPopStateListener();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getGiftBoxDataJob.run();
  });

  return (
    <>
      {
        <Show when={routeData$Server()}>
          {buildLandingPage(routeData$Server()!)}
        </Show>
      }
    </>
  );

  function buildLandingPage(props: GiftBoxV3HomeRouteData) {
    return (
      <>
        <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
        <ClientOnlyComponent component={ClientComponent.ModalHost} />

        <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
        <Switch>
          <Match
            when={
              getGiftBoxDataJob.jobState() == "success" &&
              getGiftBoxDataJob.jobResult() != null
            }
          >
            <BrandCollectionComponent
              sessionId={getGiftBoxDataJob.jobResult()!.sessionId}
              giftingKey={giftingKey}
              metadata={{
                bgImage:
                  getGiftBoxDataJob.jobResult()!.giftBoxMetadta
                    .backgroundImage ?? gitingV3defaultBg,
                expiryDate:
                  getGiftBoxDataJob.jobResult()!.giftBoxMetadta.expiry,
                amount: getGiftBoxDataJob.jobResult()!.giftBoxMetadta.amount,
                description:
                  getGiftBoxDataJob.jobResult()!.giftBoxMetadta.description,
                instructions:
                  getGiftBoxDataJob.jobResult()!.giftBoxMetadta.instructions,
              }}
              onClickBrand={(id) => {
                window.location.href = `/gift-box/m/${giftingKey}/buy/${id}`;
              }}
              getBrandsList={getBrandsList}
              landingPageData={getLandingPageData}
              setClickedUnwrap={setClickedUnwrap}
              logoUrl={props.logoUrl}
            />
          </Match>
          <Match when={getGiftBoxDataJob.jobState() == "error"}>
            <div class="text-center">{errorMessage()}</div>
          </Match>
          <Match
            when={
              getGiftBoxDataJob.jobState() == "running" ||
              getGiftBoxDataJob.jobState() == "idle"
            }
          >
            <LandingPageLoadingState
              bgimg={
                getGiftBoxDataJob.jobResult()?.giftBoxMetadta.backgroundImage ??
                gitingV3defaultBg
              }
            />
          </Match>
        </Switch>
      </>
    );
  }
}

export type LandingPageData = {
  brands: GiftBrandListingData[] | undefined;
  coinsCount: number | undefined;
  isSmartGC: boolean | null;
  state: BrandCollectionLandingPageState;
  isRtu: boolean;
  phoneNumber?: string | null;
};
